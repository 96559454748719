// organize-imports-ignore
import i18n from '@/i18n';
import { initSentry } from '@/sentry-live';
import '@/yup';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import VueTheMask from 'vue-the-mask';
import App from './AppLive.vue';
import router from './router/live';
import './vee-validate-front';

import '@/theme/live.scss';

const app = createApp(App).use(createPinia()).use(VueTheMask).use(i18n).use(router);

initSentry(app);

router.isReady().then(() => {
    app.mount('#app');
});
