import { getEnv } from '@/env/env';
import router from '@/router/live';
import { browserTracingIntegration, init, replayIntegration } from '@sentry/vue';
import type { App } from 'vue';

export const initSentry = (app: App) => {
    init({
        app,
        enabled: import.meta.env.PROD,
        dsn: 'https://212921bc4cdb4a9190ee8354f8345db9@o1290556.ingest.sentry.io/4504775487389696',
        release: getEnv().releaseName,
        tracePropagationTargets: ['live.apenday.com', 'preprod.live.apenday.com', 'int.live.apenday.com', /^\//],
        tracesSampleRate: 0.3,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1,
        environment: getEnv().envName,
        integrations: [
            browserTracingIntegration({ router }),
            replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
    });
};
